import React, { useState, useEffect, createRef, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from "material-table";
import { Paper } from '@material-ui/core';

import { userVoucherList } from '../../redux/actions/userActions'

import PatchedPagination from '../other/PatchedPagination';
import CustomDatePicker from '../other/CustomDatePicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const CompanyVoucherList = () => {

    const dispatch = useDispatch();
    const tableRef = createRef();
    const getUserVoucherList = useSelector((state) => state.getUserVoucherList.items);
    const [isShown1, setIsShown1] = useState(false);
    const [isShown2, setIsShown2] = useState(false);
    const [isShown3, setIsShown3] = useState(false);
    const [isShown4, setIsShown4] = useState(false);
    const { t } = useTranslation('profile');

    const showSubnav = (questionNo) => {
        switch (questionNo) {
            case '1': setIsShown1(!isShown1); break;
            case '2': setIsShown2(!isShown2); break;
            case '3': setIsShown3(!isShown3); break;
            case '4': setIsShown4(!isShown4); break;
            default: setIsShown1(!isShown1); setIsShown2(!isShown2); setIsShown3(!isShown3); setIsShown4(!isShown4);
        }
        // setDropdowntrigger(!dropdownTrigger);
    };

    useEffect(() => {
        dispatch(userVoucherList());
    }, [])

    return (
        <>
            <div className='col-sm-12'>
                {/* <div className='voucher-image-on-top'>
                    <img src='https://resource.ewe.rs/gui/ewe-bonus-plus-b2b-baner.jpg' alt='voucher hero'></img>
                </div> */}
                <div className="voucher-info-holder">
                    <div className='voucher-info-title'>
                        {t('bonus')}
                    </div>
                    <div className='voucher-info-faq-holder'>
                        <div className={`voucher-info-faq ${isShown1 ? 'active' : ''}`} onClick={() => showSubnav('1')}>
                            <h3>
                                <span className="icon-expand-faq-items">
                                    {
                                        isShown1
                                            ? <FontAwesomeIcon className="info-text-chevron-expanded" icon={faMinus} />
                                            : <FontAwesomeIcon className="info-text-chevron" icon={faPlus} color="#111111" />
                                    }
                                </span>
                                {t('kakoDaZnam')}
                            </h3>
                            <p className={isShown1 ? 'shown' : 'hidden'}>
                                {t('uKatalogu')}
                            </p>
                        </div>
                        <div className={`voucher-info-faq ${isShown2 ? 'active' : ''}`} onClick={() => showSubnav('2')}>
                            <h3>
                                <span className="icon-expand-faq-items">
                                    {
                                        isShown2
                                            ? <FontAwesomeIcon className="info-text-chevron-expanded" icon={faMinus} />
                                            : <FontAwesomeIcon className="info-text-chevron" icon={faPlus} color="#111111" />
                                    }
                                </span>
                                {t('kakoMogu')}
                            </h3>
                            <p className={isShown2 ? 'shown' : 'hidden'}>
                                {t('vaucer')}
                            </p>
                        </div>
                        <div className={`voucher-info-faq ${isShown3 ? 'active' : ''}`} onClick={() => showSubnav('3')}>
                            <h3>
                                <span className="icon-expand-faq-items">
                                    {
                                        isShown3
                                            ? <FontAwesomeIcon className="info-text-chevron-expanded" icon={faMinus} />
                                            : <FontAwesomeIcon className="info-text-chevron" icon={faPlus} color="#111111" />
                                    }
                                </span>
                                {t('kakoDaIskoristim')}
                            </h3>
                            <p className={isShown3 ? 'shown' : 'hidden'}>
                                {t('sledecaKupovina')}
                            </p>
                        </div>
                        <div className={`voucher-info-faq ${isShown4 ? 'active' : ''}`} onClick={() => showSubnav('4')}>
                            <h3>
                                <span className="icon-expand-faq-items">
                                    {
                                        isShown4
                                            ? <FontAwesomeIcon className="info-text-chevron-expanded" icon={faMinus} />
                                            : <FontAwesomeIcon className="info-text-chevron" icon={faPlus} color="#111111" />
                                    }
                                </span>
                                {t('vrednosti')}
                            </h3>
                            <p className={isShown4 ? 'shown' : 'hidden'}>
                                {t('vrednost')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="status-order-table">
                    <div className="editing-cart-items-table">
                        {getUserVoucherList.length > 0 ? (
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                    Container: props => <Paper {...props} elevation={0} />
                                }}
                                data={getUserVoucherList}
                                localization={{
                                    toolbar: {
                                        searchPlaceholder: 'Pretraga...',
                                        searchTooltip: 'Pretraga',
                                    },
                                    pagination: {
                                        labelRowsSelect: '',
                                        firstAriaLabel: t('prvaStrana'),
                                        firstTooltip: t('prvaStrana'),
                                        previousAriaLabel: t('preth'),
                                        previousTooltip: t('preth'),
                                        nextAriaLabel: t('sledStr'),
                                        nextTooltip: t('sledStr'),
                                        lastAriaLabel: t('posleStr'),
                                        lastTooltip: t('posleStr'),
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                }}
                                tableRef={tableRef}
                                columns={[
                                    {
                                        title: <>{t('vauc')} <i className="fas fa-sort mx-1"></i></>,
                                        field: "acPromoCode",
                                    },
                                    {
                                        title: <>{t('datumKre')} <i className="fas fa-sort mx-1"></i></>,
                                        field: "adDateCreate",
                                        type: "date",
                                        dateSetting: { locale: "sr-RS" },
                                        filterComponent: (props) => <CustomDatePicker {...props} />,
                                    },
                                    {
                                        title: <>{t('datumVaz')} <i className="fas fa-sort mx-1"></i></>,
                                        field: "adDateValid",
                                        type: 'date',
                                        dateSetting: { locale: "sr-RS" },
                                        filterComponent: (props) => <CustomDatePicker {...props} />,
                                    },
                                    {
                                        title: <>{t('trajanje')} <i className="fas fa-sort mx-1"></i></>,
                                        field: "anPromoCodeDurationDay",
                                        render: item => {
                                            return item.anPromoCodeDurationDay + " dana";
                                        },
                                    },
                                    {
                                        title: <>{t('vrednostV')} <i className="fas fa-sort mx-1"></i></>,
                                        field: "anPromoCodeValue",
                                        type: "currency",
                                        currencySetting: {
                                            currencyCode: "RSD"
                                        },
                                        filterCellStyle: { textAlign: "right" },
                                    },
                                    {
                                        title: "",
                                        field: "isUsed",
                                        cellStyle: { textAlign: "center" },
                                        headerStyle: { textAlign: "center" },
                                        filterCellStyle: { textAlign: "center" },
                                        sorting: false,
                                        render: item => {
                                            if (item.isCanceled == 1) {
                                                if (item.isUsed == 0) {
                                                    item.isUsed = "Istekao"
                                                } else if (item.isUsed == 1) {
                                                    item.isUsed = "Iskorišćen"
                                                }
                                            } else if (item.isCanceled == 0) {
                                                if (item.isUsed == 1) {
                                                    item.isUsed = "Iskorišćen"
                                                } else if (item.isUsed == 0) {
                                                    item.isUsed = "Nije iskorišćen"
                                                }
                                            }
                                            return (
                                                <div className={`btn-voucher-editing-cart ${item.isUsed == "Iskorišćen" ? "orange" : item.isUsed == "Istekao" ? "red" : "green"}-btn-editing-cart`}>
                                                    <div className={`btn-custom-editing-cart-text order-status-text-status`}>{item.isUsed}</div>
                                                </div>
                                            )
                                        }
                                    },
                                    // {
                                    //     title: "Iskorišćen",
                                    //     field: "isUsed",
                                    //     cellStyle: { textAlign: "center" },
                                    //     headerStyle: { textAlign: "center" },
                                    //     filterCellStyle: { textAlign: "center" },
                                    //     render: item => {
                                    //         if (item.isUsed == 0) {
                                    //             return "Ne";
                                    //         } else {
                                    //             return "Da";
                                    //         }
                                    //     }
                                    // },
                                ]}
                                options={{
                                    filtering: true,
                                    draggable: false,
                                    thirdSortClick: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50],
                                    headerStyle: {
                                        padding: "8px",
                                        whiteSpace: "nowrap"
                                    },
                                    cellStyle: {
                                        padding: "8px"
                                    },
                                    filterCellStyle: {
                                        padding: "8px"
                                    },
                                }}
                                title=""
                                icons={{
                                    Filter: () => <i className="fas fa-search"></i>,
                                }}
                            />
                        ) : (
                            <div className="alert alert-primary" role="alert">
                                {t('nemaVaucera')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyVoucherList