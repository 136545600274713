import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import imageExists from 'image-exists';

import InputQtyPlusMinus from '../other/InputQtyPlusMinus';
import CurrencyFormat from '../other/CurrencyFormat';
import AddToCartButton from '../cart/AddToCartButton';
import WishListButton from '../other/WishListButton';
import CompareButton from '../other/CompareButton';
import CatalogProductBadgeImage from './CatalogProductBadgeImage';
import CatalogProductBadgeAction from './CatalogProductBadgeAction';
import CatalogProductBadge from './CatalogProductBadge';
import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';
import { removeSpecialChar } from '../../helperFunctions'

const CatalogTableItem = ({ item, activeCart, set3DModalFunctionSet }) => {

    const imageExistsFunction = () => {
        var tmp = '';
        imageExists('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png', img => {
            tmp = img;
        });
        return tmp;
    }

    const { t } = useTranslation('routes');
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const iconPath = process.env.PUBLIC_URL + '/media/';
    const getUserInfo = useSelector((state) => state.userInfo);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const [qtyProduct, setQtyProduct] = useState(1);
    const [showActionInfo, setShowActionInfo] = useState(false);
    const [showActionKey, setShowActionKey] = useState();
    // var modalIcon = imageExistsFunction();
    const [modalIcon, setModalIcon] = useState(false);
    const userInfo = useSelector((state) => state.userInfo);
    const isLoggedIn = userInfo.userInfo;

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }
    const focusOnTop = () => {
        // window.scrollTo(0, 0)
    };

    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };

    const showText = (key) => {
        setShowActionInfo(true)
        setShowActionKey(key)
    }

    const hideText = () => {
        setShowActionInfo(false)
    }


    const set3DModalFunctionSetFunction = () => {
        set3DModalFunctionSet(item);
    }


    useEffect(() => {
        imageExists('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png', img => {
            setModalIcon(img);
        });
    }, [item.acProduct]);

    return (
        <>
            <tr>
                <td className="products-list-table-item-holder">
                    <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                        <img src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}.jpg`} alt={item.acName} className="catalog-table-item-img" onError={(e) => addDefaultSrc(e)} />
                    </Link>

                    <div className="products-table-list-action-holder">
                        {item.specialList.length > 0 && (
                            item.specialList.map((item, key) => (
                                <CatalogProductBadgeAction view="tableItem" key={key} item={item} />
                            ))
                        )}

                        {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                            <>
                                {item.acIconType == 0 && (
                                    <CatalogProductBadge view="tableItem" key={key} item={item} />
                                )}
                            </>
                        ))}
                    </div>
                </td>
                <td className="list-action-badges-table">
                    {item.specialList && item.specialList.length > 0 && item.specialList.map((item, key) => (
                        item.acText == 'Vaucher' && item.acType == 'C' &&
                        <div className="product-catalog-badge-image" title={item.acNote}>
                            <img src={`https://resource.ewe.rs/vouchers/vauceri.png`} alt="Bedž" className="img-fluid" />
                        </div>
                    ))}
                    {item.benefitPoints && isLoggedIn && item.benefitPoints.length > 0 && item.benefitPoints.map((item, key) => (
                        // item.acName == 'Voćkice' &&
                        <div className="product-catalog-badge-image" title={`U toku je prikpljanje poena za sencor akciju. Ovaj artikal vam donosi ${item.anPoints} poena`}>
                            {item.anPoints == 5 &&
                                <img src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_5.png`} alt="Bronzani novčić" className="img-fluid" />
                            }
                            {item.anPoints == 10 &&
                                <img src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_10.png`} alt="Srebrni novčić" className="img-fluid" />
                            }
                            {item.anPoints == 20 &&
                                <img src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_20.png`} alt="Zlatni novčić" className="img-fluid" />
                            }
                            {/* <span className='product-catalog-badge-coin-value'>{item.anPoints}</span> */}
                        </div>

                    ))}
                    {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                        <>
                            {item.acIconType != 0 && (
                                <CatalogProductBadgeImage key={key} item={item} />
                            )}
                        </>
                    ))}

                    {modalIcon == 1 && (
                        <div
                            className="product-catalog-badge-image product-catalog-badge-image-360"
                            onClick={(e) => set3DModalFunctionSetFunction(item)}
                        >
                            <img src={process.env.PUBLIC_URL + `/360logo.png`} alt="3D logo" />
                        </div>
                    )}
                </td>
                {/* <td>
                {item.specialList != null && item.specialList.length > 0 && (
                    item.specialList.map((item, key) => (
                        <>
                            <div className="product-table-view-badge text-center" key={key} style={{ backgroundColor: `${item.acColor}` }} onMouseEnter={() => showText(key)} onMouseLeave={hideText}>

                                {item.acIconClass.includes('<i', 0) ? <div dangerouslySetInnerHTML={{ __html: item.acIconClass }} /> : <i className={item.acIconClass}></i>}
                                {showActionInfo && showActionKey == key && item.acSpecialName && <span className="table-action-info" style={{ backgroundColor: item.acColor }}>{item.acSpecialName}</span>}
                            </div>
                        </>
                    ))
                )}
            </td> */}
                <td>
                    {item.acProduct}
                </td>
                <td className="catalog-table-item-name">
                    <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`} title={item.acName}>
                        {item.acSeoHeader ? item.acSeoHeader.replace(" 1****", "") : (item.acDept.replace(" 1****", "") + ' ' + item.acName)}
                    </Link>
                </td>
                {getUserInfo.userInfo && <td className="text-center">{item.anStock}</td>}
                {getUserInfo.userInfo && <td className="text-center">{item.anReserved}</td>}
                <td style={{ textAlign: 'center' }}>
                    <CompareButton title="" product={item} />
                    {getUserInfo.userInfo && <WishListButton title="" product={item} />}
                </td>
                <td className={`text-center table-view-price`}>
                    {item.isPromotion == 1 && getUserInfo.userInfo && (
                        item.anOldPrice > 0 && (
                            <>
                                <s className="old-price-item-catalog"><CurrencyFormat num={item.anOldPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : 'RSD'}</s><br />
                            </>
                        )
                    )}
                    <span className={`${item.isPromotion == 1 && 'price-catalog-red-table'}`}><CurrencyFormat num={getUserInfo.userInfo ? item.anPrice : item.anRetailPrice} /> {activeCurrency.acShortName} </span>
                </td>
                {
                    getUserInfo.userInfo && (
                        <>
                            <td className={`text-center table-view-price`}>
                                {item.anRecommendedRetailPrice > 0 &&
                                    <>
                                        <CurrencyFormat num={item.anRecommendedRetailPrice} /> RSD
                                    </>
                                }
                            </td>
                            {/* <td className="text-center">{item.anStock}</td> */}
                        </>
                    )
                }
                {getUserInfo.userInfo ? (
                    <>
                        {item.anStock > 0 ? (
                            <>
                                {item.anStock == item.anReserved ? (
                                    <>
                                        <td colSpan={'2'}>
                                            <div className='icon-position' >
                                                <button className="btn btn-sm btn-call w-100"><img src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-telefon" style={{ width: "16px", paddingBottom: "3px", marginLeft: "-19px", marginRight: "-3px" }} />&nbsp; Pozovite</button>
                                            </div>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className="text-center qty-table-td">
                                            {item.anStock && <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={item.anStock} />}

                                        </td>
                                        <td>
                                            <div className="text-center btn btn-success w-100 btn-sm">
                                                <AddToCartButton qtyValue={qtyProduct} product={item} name="" activeCart={activeCart} />
                                            </div>
                                        </td>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <td colSpan={'2'}>
                                    <div className='icon-position'>
                                        <button className="btn btn-sm btn-call w-100 "><img src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-telefon " style={{ width: "16px", paddingBottom: "3px", marginLeft: "-19px", marginRight: "-3px" }} />&nbsp; Pozovite</button>
                                    </div>
                                </td>
                            </>
                        )}
                    </>
                ) : (
                        <Link className="w-100" to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acSeoUrl)}`} onClick={(e) => focusOnTop()}>
                        <button className="btn btn-success w-100 btn-sm"><i className="fas fa-external-link-square-alt"></i> Detaljnije</button>
                    </Link>
                )}

            </tr >

        </>

    )
}

export default CatalogTableItem;