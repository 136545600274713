import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';

import FormInput from '../form/FormInput';
import MessageInfoBox from './MessageInfoBox';

import captchaImg from '../../../src/img/captcha.jpg';
import { useTranslation } from 'react-i18next';

const IntroPageContactForm = () => {

    const [captcha, setCaptcha] = useState('I');
    const [user, setUser] = useState({
        username: ""
    });
    const [honeyPot, setHoneyPot] = useState(0);
    const { t } = useTranslation('other');

    const characters = 'qwertyuopasdfghjkzxcvbnm1234567890';
    // var captcha = '';
    useEffect(() => {
        generateString(6) // Function called here and save in captcha variable
    }, [])

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
        //  return result;
    }

    // console.log(captcha, 'kepča')

    const [values, setValues] = useState({
        emailContact: "",
        nameContact: "",
        textContact: "",
        username: ""
    });

    const [errors, setErrors] = useState({
        emailContact: 1,
        nameContact: 1,
        textContact: 1,
        username: 1
    });

    const [validInput, setValidInput] = useState({
        emailContact: 0,
        nameContact: 0,
        textContact: 0,
        username: 0
    })

    const onChangeFunction = (e) => {
        // honeypot provera
        if (e.target.name == 'contact_us_via_pigeon') {
            if (e.target.value != '') {
                setHoneyPot(1)
            } else {
                setHoneyPot(0)
            }
        }
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (honeyPot == 0) {
            if (captcha == user.username) {
                // ovde ide kod koji se izvršava nakon uspešne captcha validacije
                setValidInput({
                    ...validInput,
                    emailContact: values.emailContact == '' && 1,
                    nameContact: values.nameContact == '' && 1,
                    textContact: values.textContact == '' && 1,
                    username: values.username == '' && 1,
                });

                if (errors.emailContact == 0 && errors.nameContact == 0 && errors.textContact == 0 && errors.username == 0) {

                    //slanje email-a
                    axios({
                        method: "POST",
                        url: "https://newb2b.ewe.rs:5000/contact",
                        data: {
                            values: values,
                        }
                    }).then((response) => {

                        if (response.data.isOk == 1) {

                            toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                                className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                            })

                            //resetovanje inputa i provera obaveznih polja
                            setValidInput({
                                emailContact: 0,
                                nameContact: 0,
                                textContact: 0,
                                username: 0
                            })

                            setErrors({
                                emailContact: 1,
                                nameContact: 1,
                                textContact: 1,
                                username: 1
                            })

                            setValues({
                                emailContact: "",
                                nameContact: "",
                                textContact: "",
                                username: "",
                            })

                            setUser({
                                username: ""
                            })

                        }
                    });

                } else {
                    toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                        className: 'red-background',
                    });
                }
            } else {
                setUser({
                    username: ""
                })
                toast(<MessageInfoBox message='errorRequiredCaptcha' type={0} />, {
                    className: 'red-background',
                });
            }
        }

    };

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setValues({ ...values, [name]: value });
        user[name] = value;
        setUser(user);
    }
    return (
        <>
            <p>
                <b>{t('eweComp')}</b><br />
                <a href="http://maps.google.com/?q=44.857082,20.385310">{t('caraDusanaZemun')}<br /></a>
                {t('1180')} < br />
                {t('centrala')}<br />
            </p>
            <form name="contactFormLogout" className="contactFormLogout">
                <FormInput
                    type="text"
                    name="nameContact"
                    placeholder={t('ime')}
                    isRequired="1"
                    nameClass="contactName"
                    messageError={t('obaveznoPolje')}
                    value={values['nameContact']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInput.nameContact}
                    setErrorsFunc={setErrorsFunction}
                />
                <FormInput
                    type="email"
                    name="emailContact"
                    placeholder={t('email')}
                    isRequired="1"
                    nameClass="contactEmail"
                    messageError="Email nije u dobrom formatu!"
                    value={values['emailContact']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInput.emailContact}
                    setErrorsFunc={setErrorsFunction}
                />
                <FormInput
                    type="textarea"
                    name="textContact"
                    isRequired="1"
                    nameClass="contactDesc"
                    placeholder={t('poruka')}
                    messageError={t('obaveznoPolje')}
                    value={values['textContact']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInput.textContact}
                    setErrorsFunc={setErrorsFunction}
                />
                <div className="form-group row contact-captcha-image-code unselectable">
                    <div className="mask-captcha-loyalty"></div>
                    <p id="captcha" style={{ top: "25px", left: "50%", position: "absolute", transform: "translate(-50%, 0)", fontSize: "1.5rem", marginBottom: "0.5rem", fontWeight: "500", lineHeight: "1.2" }}>{captcha}</p>
                    <img src={captchaImg} className="mt-3 mb-3 w-100" height="50" alt="captcha" />
                </div>
                <FormInput
                    type="text"
                    name="username"
                    placeholder={t('unesitekod')}
                    nameClass="userName"
                    messageError={t('molimoKod')}
                    isRequired="1"
                    value={values['username']}
                    onChange={(e) => handleChange(e)}
                    validInputValue={validInput.username}
                    setErrorsFunc={setErrorsFunction}
                    autoComplete="off"
                />
                {/* HONEY POT POLJE */}
                <input type="text" name="contact_us_via_pigeon" value="" style={{ display: "none" }} autoComplete="off" onChange={(e) => onChangeFunction(e)} />
                {/* <input
                    type="text"
                    id="inputType"
                    className="form-control"
                    placeholder="Unesite kod sa slike..."
                    name="username"
                    onChange={handleChange}
                    autoComplete="off"
                /> */}
                <button type="submit" className="btn btn-light emailfrom" onClick={handleSubmit}>{t('posalji')}</button>

            </form>
        </>
    )
}

export default IntroPageContactForm