import React from 'react';
import CurrencyFormat from '../other/CurrencyFormat';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const CartInfoTotalSum = () => {

    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const { t } = useTranslation('cart');

    return (
        <div className="main-cart-total-sum-block">
            <div className="table-cart-sum">
                <table className="table">
                    <tbody>
                        <tr>
                            <td className="main-cart-table-sum-name">{t('iznos')}</td>
                            <td className="main-cart-table-sum-value"><CurrencyFormat num={getActiveCartFunction.anValueNoPdv} /> {activeCurrency.acShortName}</td>
                        </tr>
                        <tr>
                            <td className="main-cart-table-sum-name">{t('suma')}</td>
                            <td className="main-cart-table-sum-value">- <CurrencyFormat num={getActiveCartFunction.anVoucher} /> {activeCurrency.acShortName}</td>
                        </tr>
                        <tr>
                            <td className="main-cart-table-sum-name">{t('porez')}</td>
                            <td className="main-cart-table-sum-value"><CurrencyFormat num={getActiveCartFunction.anPdv} /> {activeCurrency.acShortName}</td>
                        </tr>
                        <tr>
                            <td className="main-cart-table-sum-name total-sum-cart-main-td">{t('ukupnoZaUplatu')}</td>
                            <td className="main-cart-table-sum-value total-sum-cart-main-td"><CurrencyFormat num={getActiveCartFunction.anValueWithPdv} /> {activeCurrency.acShortName}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CartInfoTotalSum;