import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PDFViewer } from "@react-pdf/renderer";

import CompareProductsPdfItems from './templates/CompareProductsPdfItems';

const CompareProductsPdf = () => {
    const compareItems = useSelector((state) => state.compareItems.compareItems);
    const currency = useSelector((state) => state.activeCurrency.item.acShortName);
    const [filters, setFilters] = useState([]);

    const makeFilters = (com) => {
        let arrSpec = [];
        let spec = [];
        for (let i = 0; i < com.length; i++) {
            for (let y = 0; y < com[i].spec.list.length; y++) {
                arrSpec.push(com[i].spec.list[y]);
            }
        }
        let arra = arrSpec.sort((a, b) => parseFloat(a.anPriority) - parseFloat(b.anPriority));
        let filtersArr = [];
        let obj = {};
        arra.forEach((item, index) => {
            if (!obj[item.acGroupName]) {
                obj[item.acGroupName] = {};
                obj[item.acGroupName]["anPriority"] = item.anPriority;
                obj[item.acGroupName]["acGroupName"] = item.acGroupName;
                obj[item.acGroupName]["anGroupId"] = item.anGroupId;
                obj[item.acGroupName]["filters"] = [];
                item.filterSet.forEach((item2) => {
                    obj[item.acGroupName]["filters"].push(item2);
                });
            } else {
                item.filterSet.forEach((item2) => {
                    obj[item.acGroupName]["filters"].push(item2);
                });
                obj[item.acGroupName]["filters"].sort((a, b) => parseFloat(a.anPriority) - parseFloat(b.anPriority));
            }

            //filtrira da se ne duplira podgrupa
            obj[item.acGroupName]["filters"] = obj[item.acGroupName]["filters"].filter((v, i, self) => self.findIndex((itm) => itm.acFilterSet === v.acFilterSet) === i);
        });
        for (const property in obj) {
            filtersArr.push(obj[property]);
        }
        return filtersArr;
    };

    useEffect(() => {
        setFilters(makeFilters(compareItems));
    }, []);

    return (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            {compareItems.length > 0 && <CompareProductsPdfItems items={compareItems} currency={currency} filters={filters} />}
        </PDFViewer>
    );
}

export default CompareProductsPdf;
