import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from "material-table";
import { Paper } from '@material-ui/core';

import { editCompanyUserList, getCompanyDepartment, getRoles, editUserFunction } from '../../redux/actions/userActions';

import PatchedPagination from '../other/PatchedPagination';
import ModalAdminUsers from '../modal/ModalAdminUsers';
import ModalAddNewUser from '../modal/ModalAddNewUser';
import { useTranslation } from 'react-i18next';

const AdminTab = ({ loggedUser }) => {

    const dispatch = useDispatch();
    const tableRef = createRef();
    const getCompanyUserList = useSelector((state) => state.getCompanyUserList.items);
    const getCompanyDepartmentList = useSelector((state) => state.getCompanyDepartmentList.items);
    const getRolesList = useSelector((state) => state.getRolesList.items);

    const [show, setShow] = useState(false)
    const [showNew, setShowNew] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [user, setUser] = useState("")
    const [check, setCheck] = useState(false)
    const [companyUserList, setCompanyUserList] = useState(getCompanyUserList)
    const { t } = useTranslation('profile');

    const handleInput = (val, rowData, event) => {
        setModalContent(val)
        setCheck(event.target)
        setShow(true)
        setUser(rowData)
    }

    const handleClose = (val) => {
        setShow(val);
        setShowNew(val);
    };

    const sendData = (values, data) => {
        dispatch(editUserFunction(values, data));
    }

    const addNewUser = () => {
        setShowNew(true)
    }

    useEffect(() => {
        dispatch(editCompanyUserList());
        dispatch(getCompanyDepartment());
        dispatch(getRoles());
    }, [])

    useEffect(() => {
        setCompanyUserList(getCompanyUserList)
    }, [getCompanyUserList])

    let adminRole = getCompanyUserList.filter(item => item.anRoleKey == 1)

    return (
        <>
            <div className="text-right m-4">
                <div className="btn-sbmit-delviery text-center" onClick={addNewUser}>{t('dodajKorisnika')}</div>
            </div>
            <MaterialTable
                components={{
                    Pagination: PatchedPagination,
                    Container: props => <Paper {...props} elevation={0} />
                }}
                data={getCompanyUserList}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Pretraga...',
                        searchTooltip: 'Pretraga',
                    },
                    pagination: {
                        labelRowsSelect: '',
                        firstAriaLabel: t('prvaStrana'),
                        firstTooltip: t('prvaStrana'),
                        previousAriaLabel: t('preth'),
                        previousTooltip: t('preth'),
                        nextAriaLabel: t('sledStr'),
                        nextTooltip: t('sledStr'),
                        lastAriaLabel: t('posleStr'),
                        lastTooltip: t('posleStr'),
                    },
                    header: {
                        actions: t('prmPod')
                    },
                }}
                tableRef={tableRef}
                columns={[
                    {
                        title: <>{t('imeIprezime')}<i className="fas fa-sort mx-1"></i></>,
                        field: "acFirstName",
                        render: item => {
                            return item.acFirstName + " " + item.acLastName
                        }
                    },
                    {
                        title: <>{t('email')} <i className="fas fa-sort mx-1"></i></>,
                        field: "acEmail",
                    },
                    {
                        title: <>{t('radnja')} <i className="fas fa-sort mx-1"></i></>,
                        field: "acDepartment",
                    },
                    {
                        title: <>{t('rola')} <i className="fas fa-sort mx-1"></i></>,
                        field: "acRole",
                    },
                ]}
                actions={[
                    {
                        icon: () => <div className="btn-custom-editing-cart red-btn-editing-cart admin-tab-buttons">
                            <div className="btn-custom-editing-cart-text order-status-text-status">
                                {t('promeni')}
                            </div>
                        </div>,
                        tooltip: t('promeniPodatke'),
                        onClick: (event, rowData) => handleInput("input", rowData, event)
                    },
                    rowData => ({
                        icon: () => <input type="checkbox" name="isActive" defaultChecked={rowData.isActive == 0 && true} />,
                        tooltip: t('deaktivirajKorisnika'),
                        onClick: (event, rowData) => handleInput("active", rowData, event),
                        hidden: adminRole.length == 1 || loggedUser.anUserKey == rowData.anUserKey && true
                    })
                ]}
                options={{
                    filtering: true,
                    draggable: false,
                    thirdSortClick: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsColumnIndex: -1,
                    cellStyle: {
                        padding: "8px",
                        whiteSpace: "nowrap"
                    },
                    headerStyle: {
                        whiteSpace: "nowrap",
                        padding: "8px"
                    },
                    filterCellStyle: {
                        padding: "8px"
                    }
                }}
                icons={{
                    Filter: () => <i className="fas fa-search"></i>,
                }}
                title=""
            />
            {show &&
                <ModalAdminUsers item={user && user} handleClose={handleClose} sendData={sendData} show={show} modalContent={modalContent} getCompanyDepartmentList={getCompanyDepartmentList} getRolesList={getRolesList} check={check} loggedUser={loggedUser} />
            }
            {showNew &&
                <ModalAddNewUser handleClose={handleClose} show={showNew} getCompanyDepartmentList={getCompanyDepartmentList} getRolesList={getRolesList} />
            }
        </>
    )
}

export default AdminTab