import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getCartFunction } from '../../redux/actions/cartActions';
import { globalStatistic } from "../../redux/actions/otherActions";
import { useTranslation } from 'react-i18next';

const CartMenuItem = ({ shoppingCartViewFunction, sidebar }) => {
    console.log("udje u cartMenuItem");
    const dispatch = useDispatch();
    const getCartListFunction = useSelector((state) => state.getCartListFunction);
    const getCartItemsList = useSelector((state) => state.getCartItemsList);
    const getActiveCurrency = useSelector((state) => state.activeCurrency);
    const { t } = useTranslation(['cart', 'routes']);

    useEffect(() => {
        dispatch(getCartFunction(getActiveCurrency.item._id));
    }, []);


    const functionShowCartModal = (shoppingCartView, modalMenu) => {
        shoppingCartViewFunction(shoppingCartView, modalMenu);
    };

    const gatherStatistic = () => {
        // nemamo anMenuKey za korpu
        // dispatch(globalStatistic('0', 'MENU'));
    }

    return (
        <>
            {getCartListFunction.items.map((item, key) => (
                <div key={key}>
                    {item.isActive === 1 && (
                        <div
                            className="shopping-cart-menu-item"
                            // onMouseEnter={() => functionShowCartModal(1, 0)}
                            // onMouseLeave={() => functionShowCartModal(0, 0)}
                            onClick={() => { functionShowCartModal(1, 0); gatherStatistic(); }}

                        >
                            <div className={`item-name`}>
                                <div className="icon-item-sidebar">
                                    <img src="/images/korpa.png" alt="ikonica korpe" title={item.acCartName == 'Default' ? t('cart:vasaKorpa') : item.acCartName} />
                                </div>
                                {sidebar ? (
                                    <span className="shopping-cart-item-name"><span>{getCartItemsList.items.length}</span></span>
                                ) : (
                                    <span className={`${item.acCartName.includes("Klonirani") && "clone-cart-name"} shopping-cart-item-name`} title={item.acCartName == 'Default' ? t('cart:vasaKorpa') : item.acCartName}><span>{getCartItemsList.items.length}</span>{item.acCartName == 'Default' ? t('cart:vasaKorpa') : item.acCartName}</span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}

        </>
    );
};

export default CartMenuItem;
/*
class CartMenuItem extends React.Component {

    
    constructor(props) {
        super(props);
    };

    componentDidMount() {
        this.props.getCartFunction();
    };
    
    functionShowCartModal = (shoppingCartView, modalMenu) => {
        // shoppingCartViewFunction(shoppingCartView, modalMenu)
        this.props.shoppingCartViewFunction(shoppingCartView, modalMenu);
    };

    render() {
        return (
            <>
                { this.props.getCartListFunction.items.map((item, key) => (
                    <div key={key}>
                        { item.isActive === 1 && (
                            <div 
                                className="shopping-cart-menu-item" 
                                onMouseEnter={() => this.functionShowCartModal(1, 0)} 
                                onMouseLeave={() => this.functionShowCartModal(0, 0)}
                                
                            >
                                <div className={`item-name`}>
                                    <div className="icon-item-sidebar">
                                        <i className="fas fa-shopping-basket"></i>
                                    </div>
                                    { this.props.sidebar ? (
                                        <span className="shopping-cart-item-name"><span>{this.props.getCartItemsList.items.length}</span></span>
                                    ) : (
                                        <span className="shopping-cart-item-name"><span>{this.props.getCartItemsList.items.length}</span>{item.acCartName == 'Default' ? {t('vasaKorpa')} : item.acCartName}</span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                
            </>
        );
    };
};

const mapStateToProps = (state) => {
    return { 
        getCartListFunction: state.getCartListFunction,
        getCartItemsList: state.getCartItemsList,
    };
};

export default connect(
    mapStateToProps,
    { getCartFunction },
)(CartMenuItem);*/

/*
const CartMenuItem = ({ shoppingCartViewFunction }) => {

    const functionShowCartModal = (shoppingCartView, modalMenu) => {
        shoppingCartViewFunction(shoppingCartView, modalMenu)
    };

    return (
        <div 
            className="shopping-cart-menu-item" 
            onMouseEnter={() => functionShowCartModal(1, 0)} 
            onMouseLeave={() => functionShowCartModal(0, 0)}
        >
            <div className={`item-name`}>
                <div className="icon-item-sidebar">
                    <i className="fas fa-shopping-basket"></i>
                </div>
                <span className="shopping-cart-item-name">Korpa 11</span>
            </div>
        </div>
    );
};

export default CartMenuItem;*/