import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import imageExists from 'image-exists';

// import styles from '../../css/Catalog.module.css'

import InputQtyPlusMinus from '../other/InputQtyPlusMinus';
import AddToCartButton from '../cart/AddToCartButton';
import CurrencyFormat from '../other/CurrencyFormat';
import WishListButton from '../other/WishListButton';
import CompareButton from '../other/CompareButton';
import CatalogProductBadge from './CatalogProductBadge';
import CatalogProductBadgeImage from './CatalogProductBadgeImage';
import CatalogProductBadgeAction from './CatalogProductBadgeAction';
import globalVariable from '../../global';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const CatalogItem = ({ item, activeCart, set3DModalFunctionSet, categoryId }) => {
    // console.log(item, 'itemi')

    const imageExistsFunction = () => {
        var tmp = '';
        imageExists('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png', img => {
            tmp = img;

        });
        return tmp;
    }
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const productItem = useRef(null);
    const getUserInfo = useSelector((state) => state.userInfo);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const [modalIcon, setModalIcon] = useState(false);
    // var modalIcon = false;
    // const getMenuSettings = useSelector((state) => state.menuSettings);
    const getMenuSettings = useSelector((state) => state.menuSettings);
    const [qtyProduct, setQtyProduct] = useState(1);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const iconPath = process.env.PUBLIC_URL + '/media/';
    const userInfo = useSelector((state) => state.userInfo);
    const isLoggedIn = userInfo.userInfo;
    const { t } = useTranslation(['catalog', 'routes']);
    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };


    const focusOnTop = () => {
        // window.scrollTo(0, 0)
    };

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }

    useEffect(() => {
        // console.log(getMenuSettings, 'getMenuSettings')
        var menuSettingsItem = JSON.parse(localStorage.getItem('menuSettings'));
        setSidebarCollapsed(menuSettingsItem ? menuSettingsItem.otherPage : getMenuSettings.otherPage)
    }, [getMenuSettings]);

    useEffect(() => {
        imageExists('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png', img => {
            setModalIcon(img);
        });
    }, [item.acProduct]);

    // console.log('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png');
    // console.log(categoryId, 'categoryId')

    function handleClick() {
        // console.log(productItem, 'productItem')
    }

    const set3DModalFunctionSetFunction = () => {
        set3DModalFunctionSet(item);
    }

    // const handleClose = () => setShowModal3D(false);

    // const sliderImages = [];
    // var obj = {
    //     acImage: ""+globalVariable.imagesPath+"/products/" + item.acProduct.trim() + "_v.jpg", 
    //     acThumbnail: ""+globalVariable.imagesPath+"/products/" + item.acProduct.trim() + "_v.jpg", 
    //     acType: "image" 
    // };
    // sliderImages.push(obj);
    // console.log("ovd sam 2")
    return (
        <>
            <div className={`col-sm-4-cust ${sidebarCollapsed ? 'collapsed-sidebar-catalog' : ''}`} ref={productItem} onClick={(e) => handleClick(item)} >
                <div className="catalog-item-main">

                    <div className="prodcut-badges-holder">
                        {getUserInfo.userInfo && item.specialList != null && item.specialList.length > 0 && (
                            item.specialList.map((item2, key2) => (
                                <CatalogProductBadgeAction view="classicItem" key={key2} item={item2} />
                            ))
                        )}

                        {/* izlistavanje specijalnih akcija vezana za sam artikal */}
                        {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                            <>
                                {item.acIconType == 0 && (
                                    <CatalogProductBadge view="classicItem" key={key} item={item} />
                                )}
                            </>
                        ))}
                    </div>

                    <div className="catalog-item-image">
                        <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                            <img loading="lazy" className={`img-fluid catalog-item-image-block-img`} src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}.jpg?${new Date().getTime()}`} alt={item.acName} onError={(e) => addDefaultSrc(e)} />
                        </Link>
                        {/* <div className="prodcut-coin-images-holder">
                            {item.benefitPoints && item.specialList.length > 0 && item.specialList.map((item, key) => (
                               
                                    <div className="product-catalog-coin-image" title={item.acName}>
                                            <img src={`https://resource.ewe.rs/benefitpoint/main.png`} alt="" className="img-fluid" />
                                    </div>
                                
                            ))} 
                             </div> */}

                        <div className="prodcut-badges-images-holder">
                            {item.specialList && item.specialList.length > 0 && item.specialList.map((item, key) => (
                                item.acText == 'Vaucher' && item.acType == 'C' &&
                                <div className="product-catalog-badge-image" title={item.acNote}>
                                    <img loading="lazy" src={`https://resource.ewe.rs/vouchers/vauceri.png`} alt="Vaučeri" className="img-fluid" />
                                </div>

                            ))}
                            {item.benefitPoints && isLoggedIn && item.benefitPoints.length > 0 && item.benefitPoints.map((item, key) => (
                                // item.acName == 'Voćkice' &&
                                <div className="product-catalog-badge-image" title={`U toku je prikpljanje poena za sencor akciju. Ovaj artikal vam donosi ${item.anPoints} poena`}>
                                    {item.anPoints == 5 &&
                                        <img loading="lazy" src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_5.png`} alt="bronze-coin" className="img-fluid" />
                                    }
                                    {item.anPoints == 10 &&
                                        <img loading="lazy" src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_10.png`} alt="silver-coin" className="img-fluid" />
                                    }
                                    {item.anPoints == 20 &&
                                        <img loading="lazy" src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_20.png`} alt="golden-coin" className="img-fluid" />
                                    }
                                    {/* <span className='product-catalog-badge-coin-value'>{item.anPoints}</span> */}
                                </div>

                            ))}



                            {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                                <>
                                    {item.acIconType != 0 && (
                                        <CatalogProductBadgeImage key={key} item={item} />
                                    )}
                                </>
                            ))}
                            {modalIcon == true && (
                                <div
                                    className="product-catalog-badge-image product-catalog-badge-image-360"
                                    onClick={(e) => set3DModalFunctionSetFunction(item)}
                                >
                                    <img loading="lazy" src={process.env.PUBLIC_URL + `/360logo.png`} alt="3D logo" className="img-fluid" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="catalog-ident-box">
                        <div className="catalog-ident-item-block">{item.acProduct}</div>
                        <div className="catalog-box-compare-wish">
                            <div className="catalog-content-compare">
                                <div className="form-group form-check">
                                    <CompareButton title={t('uporedi')} product={item} />
                                </div>
                            </div>
                            {getUserInfo.userInfo && (
                                <div className="catalog-content-wish" >
                                    <div className="form-group form-check">
                                        <WishListButton title={t('catalog:listaZelja')} product={item} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="catalog-item-name">
                        <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                            {item.acSeoHeader ? item.acSeoHeader.replace(" 1****", "") : (item.acDept.replace(" 1****", "") + ' ' + item.acName)}
                        </Link>
                    </div>
                    <div className="catalog-item-other">
                        {getUserInfo.userInfo && (
                            <div className="catalog-item-ident-reserve">
                                <div className="catalog-mp-price-item">
                                    {item.anRecommendedRetailPrice > 0 && (
                                        <>
                                            {t('catalog:MP')}: <CurrencyFormat num={item.anRecommendedRetailPrice} />  {t('catalog:RSD')}
                                        </>
                                    )}
                                </div>
                                <div className="catalog-qty-item"> {t('catalog:lager')}: {item.anStock}</div>
                            </div>
                        )}
                        {item.isPromotion == 1 && getUserInfo.userInfo && (
                            <div className="catalog-item-reserved-box">
                                <div className="catalog-price-item">
                                    {item.anOldPrice > 0 && (
                                        <>
                                            <s className="old-price-item-catalog"><CurrencyFormat num={item.anOldPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : t('catalog:RSD')}</s>
                                        </>
                                    )}
                                </div>
                                <div className="catalog-qty-item">
                                    {getUserInfo.userInfo && (<div className="catalog-reseve-item"> {t('catalog:rezervisano')}: {item.anReserved}</div>)}
                                </div>
                            </div>
                        )}


                        {/* <div className="catalog-item-reserved-box">
                            <div className="catalog-price-item">
                                {item.anOldPrice > 0 && (
                                    <>
                                        <span className="old-price-catalog-price"><CurrencyFormat num={item.anOldPrice} /> RSD</span><br />
                                    </>
                                )}
                            </div>
                        </div> */}
                        <div className="catalog-item-qty-price">
                            <div className="catalog-price-item">
                                <span className={`new-price-catalog-price ${!getUserInfo.userInfo && 'price-catalog-offline'} ${item.isPromotion == 1 && 'price-catalog-red'}`}><CurrencyFormat num={getUserInfo.userInfo ? item.anPrice : item.anRetailPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : t('catalog:RSD')}</span>
                            </div>

                            {getUserInfo.userInfo && item.isPromotion == 0 && (<div className="catalog-reseve-item">{t('catalog:rezervisano')}: {item.anReserved}</div>)}
                        </div>
                        <div className={`catalog-item-buttons ${!getUserInfo.userInfo && 'catalog-item-buttons-offline'} ${item.anRecommendedRetailPrice === 0 && 'catalog-item-buttons-version2'}`}>
                            {getUserInfo.userInfo ? (
                                <>
                                    {item.anStock > 0 ? (
                                        <>
                                            {item.anStock == item.anReserved ? (
                                                <button className="btn btn-sm btn-call w-100"><img loading="lazy" src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-icon" style={{ width: "19px", paddingBottom: "4px" }} />&nbsp; {t('catalog:pozovite')}</button>
                                            ) : (
                                                <>
                                                    <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={item.anStock} />
                                                    <div className="catalog-item-add-button">
                                                        {/* <div className="btn-add-to-cart-catalog"> */}
                                                        <div className="text-center btn btn-success w-100 btn-sm">
                                                            <AddToCartButton qtyValue={qtyProduct} product={item} name={t('dodaj')} activeCart={activeCart} />
                                                        </div>
                                                    </div>
                                                </>

                                            )}

                                        </>

                                    ) : (
                                        <>
                                            <button className="btn btn-sm btn-call w-100"><img loading="lazy" src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-icon" style={{ width: "19px", paddingBottom: "4px" }} />&nbsp; {t('catalog:pozovite')}</button>

                                        </>)}
                                </>
                            ) : (
                                <Link className="w-100" to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acSeoUrl)}`} onClick={(e) => focusOnTop()}>
                                    <button className="btn btn-success w-100 btn-sm details-button"><i className="fas fa-external-link-square-alt"></i> {t('catalog:detaljnije')}</button>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CatalogItem;