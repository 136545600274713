import { useState } from 'react';
import { ActionTypes } from '../contants/action-types';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import { searchLetters } from '../../configSite';
import { toast } from 'react-toastify';
import MessageInfoBox from '../../components/other/MessageInfoBox';
import SecureLS from 'secure-ls';

let ls = new SecureLS({ encodingType: 'aes' });

export const getDeliveryInfo = () => async dispatch => {
    try {
        const attributes = {};
        const configRes = axiosinstance(attributes, '8224', 'postDeliveryScheduleList');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_DELIVERY_ITEMS',
                        payload: response.data.response.sdsList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
export const getReclamations = () => async dispatch => {
    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

        const configRes = axiosinstance(attributes, '8224', 'postReclamation');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_RECLAMATION_ITEMS',
                        payload: response.data.response.reclamationList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
export const getBrends = () => async dispatch => {
    try {
        const attributes = {};
        const configRes = axiosinstance(attributes, '8224', 'postReclamationBrends');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_BRENDS_ITEMS',
                        payload: response.data.response.reclamationBrendsList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
export const getInvoice = () => async dispatch => {
    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

        const configRes = axiosinstance(attributes, '8224', 'postInvoiceAvaible');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_INVOICE_ITEMS',
                        payload: response.data.response.newBshList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
export const getBankAccount = () => async dispatch => {
    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

        const configRes = axiosinstance(attributes, '8224', 'postCompanyBankAccount');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_BANK_ACCOUNT_ITEMS',
                        payload: response.data.response.companyBankAccount
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
export const getReservations = () => async dispatch => {
    try {
        const attributes = {};
        var userInfoData = ls.get('userInfo');
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

        const configRes = axiosinstance(attributes, '8224', 'postCompanyProductReservationList');

        axios(configRes)
            .then(function (response) {
                if (response.data.isOk == 1) {
                    dispatch({
                        type: 'SET_RESERVATION_ITEMS',
                        payload: response.data.response.productReservationList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {
        console.log(error)
    }
};
export const getSaleStatistics = () => async dispatch => {
    const attributes = {};
    var userInfoData = ls.get('userInfo');
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postSaleStatistics');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_SALE_STATISTICS_ITEMS',
                payload: response.data.response.saleStatisticList
            });
        })
        .catch(function (error) {
            console.log(error);
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};
export const getTransactions = () => async dispatch => {
    const attributes = {};
    var userInfoData = ls.get('userInfo');
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postAcctTransact');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_TRANSACTION_ITEMS',
                payload: response.data.response
            });
        })
        .catch(function (error) {
            console.log(error);
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};
export const getNewsletterMaterial = () => async dispatch => {
    const attributes = {};
    var userInfoData = ls.get('userInfo');
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postMaterialForDownload');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_NEWSLETTER_MATERIAL_ITEMS',
                payload: response.data.response.materialForDownloadList
            });
        })
        .catch(function (error) {
            console.log(error);
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const b2bNewsletterSignup = (values) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes['acEmail'] = values

    const configRes = axiosinstance(attributes, '8224', 'postB2bNewsletterSingup');

    axios(configRes)
        .then(function (response) {
            if (response.data.response.isOk == 1) {
                axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5000/newsletter",
                    data: {
                        newsletterEmail: values,
                        userEmail: userInfoData.company.department[0].user[0].acEmail
                    }
                }).then(res => {
                    if (res.data.isOk == 1) {
                        toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                            className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                        });
                    } else {
                        toast(<MessageInfoBox message="globalError" type="0" />, {
                            className: 'red-background',
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                });
            } else {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            }
        })
        .catch(function (error) {
            console.log(error);
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const exportCatalog = (values) => {
    return (dispatch, state) => {

        var userInfoData = ls.get('userInfo');
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        var activeVat = JSON.parse(localStorage.getItem('activeVat'));
        var activeProductSort = JSON.parse(localStorage.getItem('activeProductSort'));

        const attributes = {};

        attributes['anMainCategoryKey'] = values.anMainCategoryKey ? values.anMainCategoryKey : 0;
        attributes['anCategoryKey'] = values.anCategoryKey ? values.anCategoryKey : 0;
        attributes['anSubCategoryKey'] = values.anSubCategoryKey ? values.anSubCategoryKey : 0;
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['isEur'] = values.isEur ? values.isEur : activeCurrency._id;
        attributes['isPdv'] = values.isPdv ? values.isPdv : activeVat;
        attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
        attributes['anCatalogSortingType'] = values.anCatalogSortingType ? values.anCatalogSortingType : activeProductSort;
        attributes['acIdents'] = values.acIdents ? values.acIdents : '';
        attributes['anAction'] = values.anAction ? values.anAction : 0;
        attributes['anNew'] = values.anNew ? values.anNew : 0;
        attributes['anAdvice'] = values.anAdvice ? values.anAdvice : 0;
        attributes['anBestBuy'] = values.anBestBuy ? values.anBestBuy : 0;
        attributes['acTerm'] = values.acTerm ? values.acTerm : '';
        attributes['acDept'] = values.acDept ? values.acDept : '';
        attributes['acCategoryName'] = values.acCategoryName ? values.acCategoryName : '';
        attributes['acSubCategoryName'] = values.acSubCategoryName ? values.acSubCategoryName : '';
        attributes['acMainCategoryName'] = values.acMainCategoryName ? values.acMainCategoryName : '';
        attributes['isImageShow'] = values.isImageShow ? values.isImageShow : 0;
        attributes['isAttributes'] = values.isAttributes ? values.isAttributes : 0;

        const configRes = axiosinstance(attributes, '8224', 'exportCatalog');

        return axios(configRes)
            .then(res => res.data.response.product)
            .catch(function (error) {
                console.log(error);
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
};


export const globalStatistic = (itemKey, type) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    var ip = JSON.parse(localStorage.getItem('getClientIp'));

    const attributes = {};

    attributes['acIpAddress'] = ip
    attributes['acItemKey'] = itemKey
    attributes['anUserId'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
    // attributes['anCompanyOrgId'] = userInfoData ? userInfoData.company.anCompanyOrgId : 0;
    attributes['acType'] = type
    // attributes['datetime'] = new Date();


    // axios({
    //     method: "POST",
    //     url: "https://newb2b.ewe.rs:5000/write-txt-log",
    //     data: attributes
    // })

    // console.log(attributes)

    const configRes = axiosinstance(attributes, '8224', 'statisticInsert');

    axios(configRes)
        .then(function (response) {
            //console.log(response)
        })
        .catch(function (error) {
            console.log(error);

        });
};

export const getBenefitRangList = () => async (dispatch, state) => {

    const attributes = {};
    attributes['anActionTypeKey'] = 2

    const configRes = axiosinstance(attributes, '8223', 'postBenefitPoints');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_BENEFIT_RANG_LIST',
                payload: response.data.response.benefitPointsList
            });
        })
        .catch(function (error) {
            console.log(error);

        });
};

export const getBenefitCoinsOverview = () => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

    const configRes = axiosinstance(attributes, '8223', 'postBenefitPointSaleOut');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_BENEFIT_OVERVIEW',
                payload: response.data.response.b2bBenefitPointSaleOutList
            });
        })
        .catch(function (error) {
            console.log(error);

        });
};

export const getCurrentTime = () => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};

    attributes['anUserId'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;


    const configRes = axiosinstance(attributes, '8224', 'getCurrentTime');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_CURRENT_SERVER_TIME',
                payload: response.data.response.adTime
            });
        })
        .catch(function (error) {
            console.log(error);

        });
};
export const getBenefitPoinsCustomAlias = () => async (dispatch, state) => {

    const attributes = {};
    attributes['anActionTypeKey'] = 2

    const configRes = axiosinstance(attributes, '8223', 'postBenefitPoinsCustomAlias');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_SENCOR_RANG_LIST',
                payload: response.data.response.benefitPointsList
            });
        })
        .catch(function (error) {
            console.log(error);

        });
};
export const postBenefitPoints = () => async (dispatch, state) => {

    const attributes = {};
    attributes['anActionTypeKey'] = 2

    const configRes = axiosinstance(attributes, '8223', 'postBenefitPoints');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_BENEFIT_SENCOR_POINTS',
                payload: response.data.response.benefitPointsList
            });
        })
        .catch(function (error) {
            console.log(error);

        });
};
