import axios from 'axios';
import { axiosinstance } from '../../configAxios';
// import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MessageInfoBox from '../../components/other/MessageInfoBox';
// import { getProductSpecification } from './productActions';
import SecureLS from 'secure-ls';
let ls = new SecureLS({ encodingType: 'aes' });

export const functionFinishOrder = (items) => async dispatch => {
}
export const submitFinishCartOrderFunction = (attributes, cartActiveDetail, acCity, activeCurrency, history) => async dispatch => {
    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['anCurrency'] = activeCurrency._id;
    // attributes['acCompanyMail'] = '';

    // userInfoData.company.email.forEach((item) => {
    //     if (item.acType == 'cart') {
    //         attributes['acCompanyMail'] = item.acEmail;
    //     }
    // });

    let emails = userInfoData.company.email.filter(i => i.acType == 'cart')
    // if (attributes['acDelivery'] === 'K' || attributes['acDelivery'] === 'I') {

    // }

    const configRes = axiosinstance(attributes, '8224', 'finishCart');

    axios(configRes)
        .then(function (response) {
            if (response.data.response.isOk == 1) {
                axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5000/finishCart",
                    data: {
                        values: attributes,
                        cartItems: cartActiveDetail,
                        acCity: acCity,
                        activeCurrency: activeCurrency,
                        clerkResponsible: userInfoData.company.clerkResponsible[0].acEmail,
                        companyEmail: emails[0].acEmail,
                        userEmail: userInfoData.company.department[0].user[0].acEmail
                    }
                })

                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;

                dispatch({
                    type: 'SET_CART_COMPLETE',
                    payload: response.data.response.isOk
                });
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: activeCart
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });
            }


            if (response.data.response.acMsg.includes(",")) {
                response.data.response.acMsg = "cartOverStockError"
            }

            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });

            if (response.data.response.isOk == 1) {
                setTimeout(() => {
                    history.replace("/");
                }, 1500)
            }

            // setTimeout(() => {
            //     dispatch({
            //         type: 'SET_CART_COMPLETE',
            //         payload: 0
            //     });
            // }, 3000)
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getDeliveryCity = () => async dispatch => {
    const attributes = {};
    const configRes = axiosinstance(attributes, '8224', 'getPostCode');

    axios(configRes)
        .then(function (response) {
            // console.log(response, 'response');
            // return response;
            dispatch({
                type: 'SET_DELIVERY_CITY',
                payload: response.data.response.cartList
            });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getDeliveryTime = (id) => async dispatch => {
    // console.log(id, 'id')
    var tmp = id.split('-');
    const attributes = {};
    attributes['anPostCode'] = tmp[1];
    const configRes = axiosinstance(attributes, '8224', 'getDeliveryTime');

    axios(configRes)
        .then(function (response) {
            // console.log(response, 'response');
            // return response;
            dispatch({
                type: 'SET_DELIVERY_TIME',
                payload: response.data.response.deliveryTimelistForReturn
            });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getCartFunction = () => async dispatch => {

    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
    attributes['anCurrency'] = activeCurrency ? activeCurrency._id : 1;

    const configRes = axiosinstance(attributes, '8224', 'getSetCart');

    axios(configRes)
        .then(function (response) {
            if (response.data.isOk == 1) {
                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });

                ls.set('getCartListFunction', response.data.response.cartList);
                ls.set('getActiveCartFunction', response.data.response.cartList.filter(x => x.isActive == 1));
                ls.set('getCartItemsList', activeCartItems);
            }
        })
        .catch(function (error) {
            console.log(error);
        });

};

export const setActiveCartFunction = (id) => async (dispatch, state) => {

    // acCartKey
    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['acCartKey'] = id;
    attributes['anCurrency'] = activeCurrency._id;

    const configRes = axiosinstance(attributes, '8224', 'activateSetCart');

    axios(configRes)
        .then(function (response) {

            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });

            if (response.data.isOk == 1) {
                loadCartAllData(userInfoData, dispatch);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const removeCartFunction = (id) => async (dispatch, state) => {

    // acCartKey
    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['acCartKey'] = id;
    attributes['anCurrency'] = activeCurrency._id;

    const configRes = axiosinstance(attributes, '8224', 'removeSetCart');

    axios(configRes)
        .then(function (response) {
            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });

            if (response.data.response.isOk == 1) {
                loadCartAllData(userInfoData, dispatch);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const addToCart = (product, qty, activeCart) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['acCartKey'] = activeCart[0]._id ? activeCart[0]._id : '';
    attributes['anProductKey'] = product.anProductKey;
    attributes['anQty'] = qty;
    attributes['anCurrency'] = activeCurrency._id;
    attributes['isRelated'] = product.isRelatedSale ? product.isRelatedSale : 0;
    const configRes = axiosinstance(attributes, '8224', 'addItemToCart');

    axios(configRes)
        .then(function (response) {

            if (response.data.response.acInfoMessage) {
                response.data.response.acInfoMessage = response.data.response.acInfoMessage.split(":")[0];
                toast(<MessageInfoBox message={response.data.response.acInfoMessage} type={0} />, {
                    className: 'red-background',
                });
            } else if (response.data.response.acMsg) {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            }

            if (response.data.isOk == 1) {
                var activateCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activateCart[0].cartItemsList;
                setCartAllData(activeCartItems, response, dispatch);
            } else {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
                dispatch(getCartFunction())
            }

        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
            // return error;
        });
};

export const clearMessageInfo = () => async (dispatch, state) => {
    dispatch({
        type: 'CLEAR_MESSAGE_INFO',
    });
}
export const removeCartItemFunction = (anCartItemKey, activeCart) => async (dispatch, state) => {

    // console.log(activeCart)
    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['acCartKey'] = activeCart[0]._id ? activeCart[0]._id : '';
    attributes['anCartItemKey'] = anCartItemKey;
    attributes['anCurrency'] = activeCurrency._id;

    const configRes = axiosinstance(attributes, '8224', 'removeSetCartItem');

    axios(configRes)
        .then(function (response) {

            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });

            if (response.data.response.isOk == 1) {
                // loadCartItemsData(userInfoData, dispatch);
                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const createNewCartFunction = (cartName) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['acCartName'] = cartName;
    attributes['anCurrency'] = activeCurrency._id;

    const configRes = axiosinstance(attributes, '8224', 'createNewCart');

    axios(configRes)
        .then(function (response) {
            if (response.data.isOk == 1) {

                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });

                ls.set('getCartListFunction', response.data.response.cartList);
                ls.set('getActiveCartFunction', response.data.response.cartList.filter(x => x.isActive == 1));
                ls.set('getCartItemsList', activeCartItems);

            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

function loadCartItemsData(userInfoData, dispatch) {

    const attributes2 = {};
    attributes2['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes2['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    attributes2['anCurrency'] = activeCurrency._id;
    const configRes2 = axiosinstance(attributes2, '8224', 'getSetCart');

    axios(configRes2)
        .then(function (response) {
            if (response.data.isOk == 1) {
                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

function setCartAllData(activeCartItems, response, dispatch) {
    dispatch({
        type: 'SET_CART_LIST',
        payload: response.data.response.cartList
    });
    dispatch({
        type: 'SET_ACTIVE_CART',
        payload: response.data.response.cartList.filter(x => x.isActive == 1)
    });
    dispatch({
        type: 'SET_CART_ITEMS_LIST',
        payload: activeCartItems
    });

    ls.set('getCartListFunction', response.data.response.cartList);
    ls.set('getActiveCartFunction', response.data.response.cartList.filter(x => x.isActive == 1));
    ls.set('getCartItemsList', activeCartItems);
};


function loadCartAllData(userInfoData, dispatch) {

    const attributes2 = {};
    attributes2['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes2['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    attributes2['anCurrency'] = activeCurrency._id;
    const configRes2 = axiosinstance(attributes2, '8224', 'getSetCart');

    axios(configRes2)
        .then(function (response) {
            if (response.data.isOk == 1) {
                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });
                ls.set('getCartListFunction', response.data.response.cartList);
                ls.set('getActiveCartFunction', response.data.response.cartList.filter(x => x.isActive == 1));
                ls.set('getCartItemsList', activeCartItems);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getVoucherLists = (item) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anProductKey'] = item.anProductKey;
    attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';

    const configRes = axiosinstance(attributes, '8224', 'postGetAvaibleVoucher');

    dispatch({
        type: 'SET_VOUCHER_LIST_REQUEST',
        payload: {
            items: {},
            loading: true
        }
    });

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_VOUCHER_LIST',
                payload: {
                    items: response.data.response.promoCodeList,
                    loading: false
                }
            });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const addVoucherFunction = (voucher, product, getActiveCartFunction) => (dispatch, state) => {
    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    attributes['acCartKey'] = getActiveCartFunction[0]._id ? getActiveCartFunction[0]._id : '';
    attributes['anProductKey'] = product.anProductKey;
    attributes['anCurrency'] = activeCurrency ? activeCurrency._id : 1;
    attributes['anPromoCode'] = voucher.anPromoCodeId;

    const configRes = axiosinstance(attributes, '8224', 'postAddRemoveVoucher');

    axios(configRes)
        .then(function (response) {
            if (product.acPromoCodeList.includes(voucher)) {
                if (response.data.response.isOk == 1) {
                    toast(<MessageInfoBox message={'voucherRemoveSuccess'} type={response.data.response.isOk} />, {
                        className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                    });
                } else {
                    toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                        className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                    });
                }
            } else {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            }
            if (response.data.response.isOk == 1) {
                // dispatch(getCartFunction());
                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });
                ls.set('getCartListFunction', response.data.response.cartList);
                ls.set('getActiveCartFunction', response.data.response.cartList.filter(x => x.isActive == 1));
                ls.set('getCartItemsList', activeCartItems);
                dispatch(getVoucherLists(product));
            }
        })
        .catch(function (error) {
            console.log(error);
        });

};

export const setCartClone = (item, getCurrencyItems, val) => async dispatch => {
    const attributes = {};
    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    var cartCurrency = getCurrencyItems.filter(item2 => item2.acShortName == item.acCurrency);

    attributes['anCurrency'] = val == "C" ? cartCurrency[0]._id : activeCurrency._id;
    attributes['anCartKey'] = item._id;
    attributes['acType'] = val;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'cloneOldCart');

    axios(configRes)
        .then(function (response) {
            var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
            var activeCartItems = activeCart[0].cartItemsList;
            setCartAllData(activeCartItems, response, dispatch);
            if (response.data.response.notFoundIndent != "") {
                toast(<MessageInfoBox message={"notFoundIndent"} dataItems={response.data.response.notFoundIndent} type="0" />, {
                    className: 'red-background',
                });
            } else {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            }

        })
        .catch(function (error) {
            console.log(error);
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};