import React, { useState, useEffect } from "react";
import { currencyFormat, roundNum, roundNumberCeil } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { globalStatistic } from "../../redux/actions/otherActions";

import CurrencyFormat from "../other/CurrencyFormat";
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const ProductDetailMarginBlock = (props) => {

    const { t } = useTranslation('product');

    const dispatch = useDispatch();
    const params = useParams();
    const { productId } = params;
    let ls = new SecureLS({ encodingType: 'aes' });

    const productPrice = roundNum(props.product.anPrice);
    const user = ls.get('userInfo') ? ls.get('userInfo') : '';
    const activeVat = JSON.parse(localStorage.getItem('activeVat'));
    const isVat = activeVat == 1 ? true : false;
    const currency = JSON.parse(localStorage.getItem('activeCurrency')) ? JSON.parse(localStorage.getItem('activeCurrency')) : '';

    const [price, setPrice] = useState(productPrice);
    const [marza, setMarza] = useState(user ? user.company.department[0].user[0].userSettings[0].anMargin : 0);
    const [margina, setMargina] = useState(user ? user.company.department[0].user[0].userSettings[0].anMarkUp : 0);
    const [retailPrice, setRetailPrice] = useState(roundNum((marza * price) / 100 + price));
    const [diffrence, setDifrence] = useState(roundNum((marza * price) / 100));
    const [advance, setAdvance] = useState(false);
    const [marginMarkup, setMarginMarkup] = useState("anMargin");

    useEffect(() => {
        if (marginMarkup == "anMargin") {
            setRetailPrice(roundNum((marza * price) / 100 + price));
            setDifrence(roundNum((marza * price) / 100));
        } else {
            setRetailPrice(roundNum(price / (1 - (margina / 100))));
            setDifrence(roundNum((price / (1 - (margina / 100))) - price));
        }
    }, [price]);

    useEffect(() => {
        saveOffer();
    }, [retailPrice]);

    useEffect(() => {
        setPrice(productPrice)
    }, [productPrice]);

    useEffect(() => {
        if (marginMarkup == "anMargin") {
            setRetailPrice(roundNum((marza * price) / 100 + price));
            setDifrence(roundNum((marza * price) / 100));
        } else {
            setRetailPrice(roundNum(price / (1 - (margina / 100))));
            setDifrence(roundNum((price / (1 - (margina / 100))) - price));
        }
    }, [marginMarkup]);

    useEffect(() => {
        setDifrence(roundNum((price / (1 - (margina / 100))) - price));
        setRetailPrice(roundNum(price / (1 - (margina / 100))));
    }, [margina]);

    useEffect(() => {
        setDifrence(roundNum((marza * price) / 100));
        setRetailPrice(roundNum((marza * price) / 100 + price));
    }, [marza]);


    const onChangeFunction = () => {
        if (marginMarkup == "anMargin") {
            setDifrence(retailPrice - price);
            setMarza(((retailPrice - price) / price) * 100);
        } else {
            setDifrence(roundNum(retailPrice - price));
            setMargina((1 - (price / retailPrice)) * 100)
        }
    };

    const resetValues = () => {
        if (advance) {
            setPrice(roundNum(productPrice - (productPrice * (props.product.anPaymentAdvance / 100))));

            if (marginMarkup == "anMargin") {
                setMarza(user.company.department[0].user[0].userSettings[0].anMargin);
                setRetailPrice(roundNum((marza * (productPrice - (productPrice * (props.product.anPaymentAdvance / 100)))) / 100 + (productPrice - (productPrice * (props.product.anPaymentAdvance / 100)))))
            } else {
                setMargina(user.company.department[0].user[0].userSettings[0].anMarkUp);
                setRetailPrice(roundNum((productPrice - (productPrice * (props.product.anPaymentAdvance / 100))) / (1 - (margina / 100))))
            }
        } else {
            setPrice(productPrice);
            if (marginMarkup == "anMargin") {
                setMarza(user.company.department[0].user[0].userSettings[0].anMargin);
                setRetailPrice(roundNum((marza * productPrice) / 100 + productPrice))
            } else {
                setMargina(user.company.department[0].user[0].userSettings[0].anMarkUp);
                setRetailPrice(roundNum(productPrice / (1 - (margina / 100))))
            }
        }

    }

    const saveOffer = () => {
        let offer = {};
        offer.price = roundNumberCeil(retailPrice);
        offer.currency = currency ? (currency._id == 0 ? t('rsd') : t('eur')) : t('rsd');
        offer.productId = props.productId;
        let of = JSON.stringify(offer);
        ls.set('customOffer', of);
    }

    const onCheckAdvance = (e) => {
        if (e.target.checked) {
            setAdvance(true);
            setPrice(roundNum(productPrice - (productPrice * (props.product.anPaymentAdvance / 100))));
        } else {
            setAdvance(false)
            setPrice(productPrice);
        }
    }

    const changeCalculatorDisplay = (e) => {
        setMarginMarkup(e.target.value)
    }
    const gatherStatisticCalculatorProduct = () => {
        dispatch(globalStatistic(productId, 'CALCULATOR_PRODUCT'));
    }

    return (
        <>
            <div className="product-detail-price-block product-detail-margine-block-box">
                <div className="product-margin-block">
                    <div className="top-holder-margin">
                        <div className="advance-radio-btn-product-detail">
                            <div className="form-check">
                                <input className="form-check-input smallCheck" type="checkbox" name="anPaymentAdvance" id="anPaymentAdvance" onChange={(e) => { { onCheckAdvance(e) } gatherStatisticCalculatorProduct() }} />
                                <label className="form-check-label" htmlFor="anPaymentAdvance">
                                    {t('avans')}  {advance && <small className="ml-1">({props.product.anPaymentAdvance}%)</small>}
                                </label>
                            </div>
                        </div>
                        <div className="margin-radio-btn-product-detail">
                            <div className="form-check">
                                <input className="form-check-input smallCheck" type="radio" name="anMargin" id="anMargin" value="anMargin" checked={marginMarkup == "anMargin" && true} onChange={(e) => { { changeCalculatorDisplay(e) } gatherStatisticCalculatorProduct() }} />
                                <label className="form-check-label" htmlFor="anMargin">
                                    {t('marza')}
                                </label>
                            </div>
                        </div>
                        <div className="margine-radio-btn-product-detail">
                            <div className="form-check">
                                <input className="form-check-input smallCheck" type="radio" name="anMarkup" id="anMarkup" value="anMarkup" checked={marginMarkup == "anMarkup" && true} onChange={(e) => { { changeCalculatorDisplay(e) } gatherStatisticCalculatorProduct() }} />
                                <label className="form-check-label" htmlFor="anMarkup">
                                    {t('margina')}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="product-margin-block-content">
                        <div className="product-table-margin-left">
                            <span className="mr-2">{t('cena')} <small>({isVat ? t('saPdv') : t('bezPdv')})</small>:</span>
                            <CurrencyFormat num={user.company ? price : props.product.anRetailPrice} /> {user.company ? currency.acShortName : t('rsd')}
                            {advance && <small className="ml-2">({t('ukolikoPlatiteAvansno')})</small>}
                        </div>
                        <div className="product-margin-box">
                            <form>
                                <table className="product-table-margin">
                                    <tbody>
                                        <tr>
                                            <td className="title-margine-table">
                                                {marginMarkup == "anMargin" ? t('marza2') : t('margina2')}
                                            </td>
                                            <td>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm smallInput"
                                                        id="marza"
                                                        value={roundNum(marginMarkup == "anMargin" ? (marza ? marza : 0) : (margina ? margina : 0))}
                                                        onChange={(e) => { { marginMarkup == "anMargin" ? setMarza(e.target.value == "" ? 0 : parseInt(e.target.value)) : setMargina(e.target.value == "" ? 0 : parseInt(e.target.value)) } gatherStatisticCalculatorProduct() }} />

                                                    <div className="input-group-append">
                                                        <span className="input-group-text smallIcon">
                                                            <i className="fas fa-percentage"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="icon-btn-item icon-btn-item-minus" onClick={() => { { marginMarkup == "anMargin" ? setMarza(parseInt(marza) - 1) : setMargina(parseInt(margina) - 1) } gatherStatisticCalculatorProduct() }}>
                                                    <i className="fas fa-minus"></i>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="icon-btn-item icon-btn-item-plus" onClick={() => { { marginMarkup == "anMargin" ? setMarza(parseInt(marza) + 1) : setMargina(parseInt(margina) + 1) } gatherStatisticCalculatorProduct() }}>
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="icon-btn-item icon-btn-item-minus" onClick={() => { resetValues(); gatherStatisticCalculatorProduct() }} >
                                                    <i className="fas fa-home"></i>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="second-row-mp-price">
                                            <td className="title-margine-table">{t('vasaPrCena')}</td>
                                            <td>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm smallInput"
                                                        id="mpCena"
                                                        value={roundNumberCeil(retailPrice)}
                                                        onChange={(e) => { { setRetailPrice(e.target.value == "" ? 0 : parseInt(e.target.value)) } gatherStatisticCalculatorProduct() }}
                                                        onKeyUp={onChangeFunction}
                                                    />

                                                    <div className="input-group-append">
                                                        <span className="input-group-text smallIcon">
                                                            <i className="fas fa-sync"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="second-row-mp-price">
                                            <td className="title-margine-table">{t('razlikaUceni')}</td>
                                            <td>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm smallInput"
                                                        id="cenaRazlika"
                                                        value={roundNumberCeil(diffrence)}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                            <td colSpan="3"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ProductDetailMarginBlock;
