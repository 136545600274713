import React, { useState, useEffect } from "react";
// import imageExists  from 'image-exists';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from '../other/CurrencyFormat';
import {
    removeProductCompare
} from '../../redux/actions/productActions';

import globalVariable from "../../global";
import { useTranslation } from 'react-i18next';

const CompareItemTopInfo = ({ item, key }) => {

    const dispatch = useDispatch();

    const getUserSettings = useSelector((state) => state.getUserSettings);
    // const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const getUserInfo = useSelector((state) => state.userInfo);
    const activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    const activeVat = JSON.parse(localStorage.getItem('activeVat'));
    const { t } = useTranslation(['catalog', 'product']);

    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };

    const removeProduct = (product) => {
        dispatch(removeProductCompare(product));
        // console.log(product, 'product');
    };
    // console.log(item, "item");
    return (
        <td key={key}>
            <img className="img-fluid" src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}.jpg`} alt={item.acName} onError={(e) => addDefaultSrc(e)} />
            <div className="compare-page-item-name" title={item.acName}>
                {item.acDept.replace(" 1****", "")} {item.acName}
            </div>
            <div className="compare-page-item-ident">{item.acProduct}</div>
            <div className="compare-page-item-price">
                <CurrencyFormat num={getUserInfo.userInfo ? item.anPrice : item.anRetailPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : t('catalog:RSD')} {getUserInfo.userInfo ? (parseInt(activeVat) == 1 ? t('product:saPdv') : t('product:bezPdv')) : ''}
            </div>
            <div className="remove-compare-item-page" onClick={(e) => removeProduct(item)}>
                <i className="fas fa-times"></i>
            </div>
        </td>
    )
}

export default CompareItemTopInfo;