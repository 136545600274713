import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from "material-table";

import { getDeliveryInfo, globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import PageBanner from '../components/banner/PageBanner';
import PatchedPagination from '../components/other/PatchedPagination';
import DeliveryScheduleItem from '../components/other/DeliveryScheduleItem';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';

const DeliverySchedule = () => {
    const dispatch = useDispatch();
    const tableRef = createRef();
    const getDeliveryStatus = useSelector((state) => state.getDeliveryItems.items);
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('deliverySchedule');

    useEffect(() => {
        dispatch(getPageSeo(351)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getDeliveryInfo());
        dispatch(globalStatistic(351, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content delivery-schedule-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <PageBanner categoryType="" categoryId="" pageId="351" />
                        </div>
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov1')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-table">
                                <div className="editing-cart-items-table">
                                    <MaterialTable
                                        components={{
                                            Pagination: PatchedPagination,
                                        }}
                                        data={getDeliveryStatus}
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: t('searchPlaceholder'),
                                                searchTooltip: t('searchTooltip'),
                                            },
                                            pagination: {
                                                labelRowsSelect: t('labelRowsSelect'),
                                                firstAriaLabel: t('firstAriaLabel'),
                                                firstTooltip: t('firstTooltip'),
                                                previousAriaLabel: t('previousAriaLabel'),
                                                previousTooltip: t('previousTooltip'),
                                                nextAriaLabel: t('nextAriaLabel'),
                                                nextTooltip: t('nextTooltip'),
                                                lastAriaLabel: t('lastAriaLabel'),
                                                lastTooltip: t('lastTooltip'),
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                        }}
                                        tableRef={tableRef}
                                        columns={[
                                            {
                                                title: <>{t('kolona1')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acPostCode",
                                            },
                                            {
                                                title: <>{t('kolona2')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acCity",
                                                type: "string",
                                            },
                                            {
                                                title: <>{t('kolona3')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acDeliveryCenter",
                                            },
                                            {
                                                title: <>{t('kolona4')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acDeliveryTime",
                                            },
                                        ]}
                                        options={{
                                            draggable: false,
                                            thirdSortClick: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 50],
                                            headerStyle: {
                                                whiteSpace: "nowrap",
                                                // textAlign: "center"
                                            },
                                            cellStyle: {
                                                // textAlign: "center"
                                            },
                                            rowStyle: {
                                                whiteSpace: "nowrap",
                                            },
                                            searchFieldAlignment: "left"
                                        }}
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeliverySchedule;