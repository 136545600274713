import React, { useEffect, useState, createRef } from 'react';
import InvoiceOverviewItem from '../components/other/InvoiceOverviewItem';
import MaterialTable from "material-table";
import PatchedPagination from '../components/other/PatchedPagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import styles from '../css/OrderStatus.module.css'

const InvoiceOverviewPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});

    const invoiceList = [
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154",

        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154",

        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
        {
            invoiceId: "210CM000102101",
            ident: "NOT09889",
            desc: "DELL G3 3500 15.6\" FHD 120Hz i5- 10300H 8GB 256GB lorem ipsum dolor sit amet",
            serialNo: "5190521164154"
        },
    ];

    const tableRef = createRef();

    const queries = useMediaQuery('(max-width: 768px)');
    const queries1000 = useMediaQuery('(max-width: 1000px)');
    const queries550 = useMediaQuery('(max-width: 550px)');

    function downloadInvoice(e) {
        e.preventDefault();
        // console.log('Download Invoice button clicked.');
    }
    function schedule(e) {
        e.preventDefault();
        // console.log('Schedule button clicked.');
    }
    function cancelInvoice(e) {
        e.preventDefault();
        // console.log('Cancel Invoice button clicked.');
    }

    useEffect(() => {
        dispatch(getPageSeo(768)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(768, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content invoice-overflow-hidden reclamation-status-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : 'Pregled faktura'}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="invoice-filter-line">
                                <form className="form-inline">
                                    <div className="status-order-filter-items">
                                        <div className="status-order-filter-item">
                                            <div className="form-group">
                                                <label htmlFor="inputDateFrom">Datum od: </label>
                                                <input type="date" className="form-control form-control-sm mx-sm-3" name="inputDateFrom" />
                                            </div>
                                        </div>
                                        <div className="status-order-filter-item">
                                            <div className="form-group">
                                                <label htmlFor="inputDateTo">Datum do: </label>
                                                <input type="date" className="form-control form-control-sm mx-sm-3" name="inputDateTo" />
                                            </div>
                                        </div>
                                        <div className="status-order-filter-item status-order-filter-search-input">
                                            <input type="text" className="form-control form-control-sm status-filter-search-input" />
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-table">
                                <div className="editing-cart-items-table">
                                    <MaterialTable
                                        components={{
                                            Pagination: PatchedPagination,
                                        }}
                                        data={invoiceList}
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: 'Pretraga...',
                                                searchTooltip: 'Pretraga',
                                            },
                                            pagination: {
                                                labelRowsSelect: '',
                                                firstAriaLabel: 'Prva strana',
                                                firstTooltip: 'Prva strana',
                                                previousAriaLabel: 'Prethodna strana',
                                                previousTooltip: 'Prethodna strana',
                                                nextAriaLabel: 'Sledeća strana',
                                                nextTooltip: 'Sledeća strana',
                                                lastAriaLabel: 'Poslednja strana',
                                                lastTooltip: 'Poslednja strana',
                                            },
                                            header: {
                                                actions: 'Akcije'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'Nema podataka',
                                                filterRow: {
                                                    filterTooltip: 'Filtriraj'
                                                }
                                            }
                                        }}
                                        tableRef={tableRef}
                                        // onRowClick={(evt, selectedRow) => {
                                        //     setOrderDetailFunction(selectedRow)
                                        // }}
                                        columns={[
                                            {
                                                title: <>BROJ FAKTURE <i className="fas fa-sort mx-1"></i></>,
                                                field: "invoiceId",
                                                cellStyle: {
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: "2",
                                                    backgroundColor: "white"
                                                },
                                                headerStyle: {
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: "11",
                                                    backgroundColor: "white"
                                                },
                                                filterCellStyle: {
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: "2",
                                                    backgroundColor: "white"
                                                },
                                            },
                                            {
                                                title: <>ŠIFRA <i className="fas fa-sort mx-1"></i></>,
                                                field: "ident",
                                                type: "string",
                                                hidden: queries550 && true
                                            },
                                            {
                                                title: <>NAZIV <i className="fas fa-sort mx-1"></i></>,
                                                field: "desc",
                                                type: 'string',
                                                hidden: queries1000 && true,

                                            },
                                            {
                                                title: <>SERIJSKI BROJ <i className="fas fa-sort mx-1"></i></>,
                                                field: "serialNo",
                                                hidden: queries && true
                                            },
                                            // {
                                            //     title: "SKEN FAKTURE",
                                            //     cellStyle: { position: "sticky", right: 300, zIndex: "2", backgroundColor: "white" },
                                            //     headerStyle: { position: "sticky", right: 300, zIndex: "11", backgroundColor: "white", fontWeight: "600" },
                                            //     filterCellStyle: { position: "sticky", right: 300, zIndex: "2", backgroundColor: "white" },
                                            //     render: item => {
                                            //         return (
                                            //             <div className="table-column-button">
                                            //                 <div className={`btn-custom-editing-cart blue-btn-editing-cart`}>
                                            //                     <div className={`btn-custom-table-text order-status-text-status`} onClick={downloadInvoice}><i className="fas fa-file-download"></i> Preuzmi</div>
                                            //                 </div>
                                            //             </div>
                                            //         )
                                            //     }
                                            // },
                                            {
                                                title: "AKCIJA",
                                                cellStyle: {
                                                    position: "sticky",
                                                    right: 0,
                                                    zIndex: "2",
                                                    backgroundColor: "white",
                                                    whiteSpace: "nowrap",
                                                },
                                                headerStyle: {
                                                    position: "sticky",
                                                    right: 0,
                                                    zIndex: "11",
                                                    backgroundColor: "white",
                                                },
                                                filterCellStyle: {
                                                    position: "sticky",
                                                    right: 0,
                                                    zIndex: "2",
                                                    backgroundColor: "white",
                                                },
                                                render: item => {
                                                    return (
                                                        <div className='invoice-table-button-column'>
                                                            <div className="table-column-button">
                                                                <div className="btn-custom-editing-cart blue-btn-editing-cart">
                                                                    <div className="btn-custom-table-text order-status-text-status" onClick={downloadInvoice}><i className="fas fa-file-download"></i> Preuzmi</div>
                                                                </div>
                                                            </div>
                                                            <div className="table-column-button">
                                                                <div className="btn-custom-editing-cart orange-btn-editing-cart">
                                                                    <div className="btn-custom-table-text order-status-text-status" onClick={schedule}><i className="fas fa-check"></i> Zakaži preuzimanje</div>
                                                                </div>
                                                            </div>
                                                            <div className="table-column-button">
                                                                <div className="btn-custom-editing-cart red-btn-editing-cart">
                                                                    <div className="btn-custom-table-text order-status-text-status" onClick={cancelInvoice}><i className="fas fa-times"></i> Storniraj</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                },
                                                sorting: false
                                            },
                                            // {
                                            //     title: "STORNIRANJE",
                                            //     cellStyle: { position: "sticky", right: 0, zIndex: "2", backgroundColor: "white" },
                                            //     headerStyle: { position: "sticky", right: 0, zIndex: "11", backgroundColor: "white" },
                                            //     filterCellStyle: { position: "sticky", right: 0, zIndex: "2", backgroundColor: "white" },
                                            //     render: item => {
                                            //         return (
                                            //             <div className="table-column-button">
                                            //                 <div className={`btn-custom-editing-cart red-btn-editing-cart`}>
                                            //                     <div className={`btn-custom-table-text order-status-text-status`} onClick={cancelInvoice}><i className="fas fa-times"></i> Storniraj</div>
                                            //                 </div>
                                            //             </div>
                                            //         )
                                            //     }
                                            // },
                                        ]}

                                        options={{
                                            filtering: false,
                                            draggable: false,
                                            thirdSortClick: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 50],
                                            headerStyle: {
                                                whiteSpace: "nowrap",
                                                fontWeight: 'bold',
                                            },
                                            cellStyle: {
                                                whiteSpace: "nowrap",
                                            },
                                            rowStyle: {
                                                "&:hover": {
                                                    color: "black"
                                                },

                                            }
                                            // exportButton: true
                                        }}
                                        title=""
                                    />
                                    {/* Prvobitna verzija */}
                                    {/* <table className="table editing-cart-table">
                                    <thead>
                                        <tr>
                                            <th>Broj fakture</th>
                                            <th>Šifra</th>
                                            <th>Serijski broj</th>
                                            <th>Serijski broj</th>
                                            <th>Sken fakture</th>
                                            <th>Pickup&Return</th>
                                            <th>Storniraj</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                        <InvoiceOverviewItem data="dummy" />
                                    </tbody>
                                </table> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceOverviewPage;